body {
  background-color: #F8F8F8;
  font-weight: 100;
}

h1,h2,h3,h4,h5,h6 {
/* font-weight: 100 !important;*/
}

label {
    color: #6c6c6c;
    font-size: 9.1pt;
    letter-spacing: -0.2pt;
    margin-bottom: 0.1rem;
}

.btn-b {
  min-width: 270px;
  margin-top: 5px;
}

#main-navbar {
    background-color: #3156a3 !important;
    font-size: 10pt;
    min-height: 40px;
    border-bottom: 1px solid #ccc;
}

#logo {
    background-image: url(/images/logo_white.svg);
    width: 139px;
    height: 45px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

h5 {
  font-size: 1rem;
  font-weight: 100;
  color: #878787;
  text-transform: uppercase;
}

.header5 {
  margin-top: 30px;
  margin-bottom: 15px;
}

.greybox {
    padding: 30px;
    background-color: #eaeaea;
    border: 3px solid #d5d5d5;
}

a.nav-link:hover {
    background-color: rgba(255, 255, 255, 0.23);
    border-radius: 5px;
}
a.nav-link:active {
    background-color: rgba(255, 255, 255, 0.4);
}

.p0 {
    padding: 0;
}
.p0 div {
    padding: 0;
}


.card {
   /* border-color: rgba(0, 0, 0, 0.63);*/
}

.card-header {
    padding: 0.75rem;
    /*background-color: rgba(0, 0, 0, 0.63);*/
    /*color: #fff;*/
}

 .card-footer {
    padding: 0.75rem;
   /* color: #fff;*/
 }

.dropdown-item {
    cursor: default;
}

.mark {
    background: #0053ff85;
    color: #fff;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}
.no-padding {
  padding: 0 !important;
}

.nowrap {
  white-space: nowrap;
}

.btn {
	border-radius: 0rem !important;
	border-width:2px;
  border-style: solid
  border-color: transparent;
}

.btn-primary {
  color: #fff;
  background-color: #1199df;
  border-color: #1199df;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #1199df;
  border-color: #1199df;
}

.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}


.btn-outline-primary {
  color: #1199df;
  background-color: transparent;
  background-image: none;
  border-color: #1199df;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #1199df;
  border-color: #1199df;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #1199df;
  background-color: transparent;
}

.btn-outline-primary:not([disabled]):not(.disabled):active, .btn-outline-primary:not([disabled]):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1199df;
  border-color: #1199df;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}


.form-data {
    font-weight: 400;
    color: #585858;
    letter-spacing: -0.3pt;
    font-size: 1.1rem;
}

.form-control {
  display: block;
  width: 100%;

  color: #116fa0;
  border: 2px solid #ced4da;
  border-radius: 0rem;
}

.input-lg {
  padding: 0.95rem 0.75rem;
  font-size: 1rem;
}


.form-control:focus {
  color: #1199df;
  background-color: #fff;
  border-color: #1199df;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #ccc;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #ccc;
  opacity: 1;
}

.form-control::placeholder {
  color: #ccc;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.service_block {
    white-space: nowrap;
    font-size: 9pt;
    background-color: #3156a3;
    padding: 2px 4px !important;
    display: block;
    position: relative;
    border-radius: 4px;
    color: #fff;
    text-align: center;
}


.opener {
    text-align: center;
    font-size: 9pt;
    font-weight: 700;
    color: #acacac;
    border-top: 1px dotted #acacac;
    border-bottom: 1px dotted #acacac;
    padding: 10px 0;
    cursor: pointer;
    margin: 23px 0px;
}

.opener:hover {
    color: #0d5cff;
}

.img_preview {
  display: block;
  margin: 2px 0 20px 0;
  width: 100%;
  height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #f4f4f4;
    border: 1px solid #ccc;
}
.help_text {
    color: #343a40;
    font-size: 8pt;
    line-height: 140%;
    margin: 1px 0 10px 0;
    font-weight: 100;
}

.field {
    font-weight: 400;
}

.with-background {
    margin-bottom: 1rem;
    border: 1px solid #f0f0f0;
    padding: 1px 8px;
    border-radius: 5px;
    background-color: #f8f8f8;
}


.big_preview {
  position: relative;
  display: block;
  width: 50%;
  min-height: 600px;
  margin-left:25%;
  margin-top: 70px;
  margin-bottom: 70px;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border: 10px solid #000;
}

.with-border-top {
    border-top: 1px solid #a8b8d9;
}

